import * as React from 'react';
import { CardNotObtainable } from './CardNotObtainable';
import { CardLimitedRibbon } from './CardLimitedRibbon';

type CardSymbolsProps = {
  className?: string;
  ignoreObtainable?: boolean;
  card: {
    craftable: boolean;
    dropLimitRemaining?: number;
    dropLimited?: boolean;
    obtainable?: boolean;
  };
};

export const CardSymbols = ({ card, className, ignoreObtainable }: CardSymbolsProps): JSX.Element => {
  const { craftable, dropLimited, dropLimitRemaining, obtainable } = card;
  return (
    <React.Fragment>
      {dropLimited && (
        <CardLimitedRibbon className={className} dropLimited={dropLimited} dropLimitRemaining={dropLimitRemaining} />
      )}
      {!ignoreObtainable && !obtainable && <CardNotObtainable craftable={craftable} />}
    </React.Fragment>
  );
};
