import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Icons from 'ui-library/icons';
import theme from './tags.scss';

const classNames = ClassNames(theme);

type TagProps = {
  label?: string;
  onClick: (value: string) => void;
  value: string;
  className?: string;
};

const Tag = ({ label, onClick, value, className = '' }: TagProps): JSX.Element => {
  return (
    <button
      type="button"
      className={classNames({
        tag: true,
        [className]: className,
      })}
      onClick={() => onClick(value)}
    >
      {label || value} <Icons id="close" />
    </button>
  );
};

export default Tag;
