import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import theme from './card-information.scss';

const classNames = ClassNames(theme);

type CarouselNavButtonProps = {
  disabled: boolean;
  isNext?: boolean;
  onPaginate: (direction: number) => void;
};

export const CarouselNavButton = ({ disabled, isNext, onPaginate }: CarouselNavButtonProps) => {
  return (
    <ButtonIcon
      type="submit"
      className={classNames({
        'cardinfo__arrows__button': true,
        'cardinfo__arrows__button--disabled': disabled,
      })}
      asLink
      icon={isNext ? 'chevron-right' : 'chevron-left'}
      disabled={disabled}
      onClick={() => {
        onPaginate(isNext ? 1 : -1);
      }}
    />
  );
};
