import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './toolbox.scss';

const classNames = ClassNames(theme);

interface ToolboxProps {
  className?: string;
  contentClassName?: string;
  contentWrapperClassName?: string;
  doNotStyleContent?: boolean;
  children: React.ReactNode;
  selectedInfo?: React.ReactNode;
  doNotFloat?: boolean;
}

export const Toolbox = ({
  children,
  className = '',
  contentWrapperClassName = '',
  contentClassName = '',
  doNotStyleContent,
  selectedInfo,
  doNotFloat,
}: ToolboxProps): JSX.Element => {
  const [isFloating, setIsFloating] = React.useState(false);
  const streamRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (doNotFloat) {
        return;
      }

      const element = streamRef.current;

      if (!element) {
        return;
      }
      const { y } = element.getBoundingClientRect();
      const elementShouldBeFloating = y < 56;
      if ((elementShouldBeFloating && isFloating) || (!elementShouldBeFloating && !isFloating)) {
        return;
      }

      setIsFloating(elementShouldBeFloating);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFloating, doNotFloat]);

  return (
    <div className={classNames({ toolbox: true, [className]: className })} ref={streamRef}>
      <div
        className={classNames({
          'toolbox__content__wrapper': true,
          'toolbox__content__wrapper--floating': isFloating,
          [contentWrapperClassName]: Boolean(contentWrapperClassName),
        })}
      >
        <div
          className={classNames({
            toolbox__content: !doNotStyleContent,
            [contentClassName]: Boolean(contentClassName),
          })}
        >
          {children}
        </div>
        {selectedInfo && <p className={classNames('toolbox__selected')}>{selectedInfo}</p>}
      </div>
    </div>
  );
};
