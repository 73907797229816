import type { PublicPageCardFilters } from 'model/indexTS';
import actionCreator from 'helpers/actionCreator';
import type { CardsFilteredAction } from './types';
import { UserSetCardsActionTypes } from './actionTypes';

const filterCards = (filters: PublicPageCardFilters, setId: string): CardsFilteredAction => {
  return actionCreator.createAction(UserSetCardsActionTypes.FILTERED, { filters, setId });
};

export const userSetCardsActions = {
  filterCards,
};
