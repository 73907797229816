import { useContext } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { PageModules } from '@streamloots/streamloots-types';
import { orderBy } from 'lodash';
import type { UserCard, PublicPageCardFilters, PublicPredefinedCard, OwnerCard } from 'model/indexTS';
import { TranslateInterface } from 'utils/translation';
import { overloadedContentActions, OpenOverloadedContent } from 'services/overloaded-content';
import { authSelectors } from 'services/auth';
import { userSetsSelectors } from 'services/user-sets';
import { userSetCardsSelectors } from 'services/user-set-cards';
import { AuthDialog } from 'scenes/auth';
import { CraftingModeProviderContext } from 'contexts/crafting-mode-provider';
import { CardInformationCarousel } from 'components/card-information';
import { Powers } from 'components/powers';
import trackEventManager from 'utils/event-manager';
import { setIdToView } from 'domain/public-page';
import Cards from './Cards';

interface MapStateToProps {
  cardFilters: PublicPageCardFilters;
  cards: Array<UserCard>;
  isUserAuthenticated: boolean;
}

interface MapDispatchToProps {
  openOverloadedContent: OpenOverloadedContent;
}

type TypeCards = UserCard | PublicPredefinedCard | OwnerCard;

interface ConnectedUserSetCardsProps extends MapStateToProps, MapDispatchToProps, TranslateInterface {
  modules: PageModules | undefined;
}

const ConnectedUserSetCardsBase = (props: ConnectedUserSetCardsProps) => {
  const { isUserAuthenticated, openOverloadedContent, cards, cardFilters, t } = props;

  const { isCraftingModeOpen, openCraftingModeCardView } = useContext(CraftingModeProviderContext);

  const getSortedCards = memoize((userCards: Array<UserCard>) =>
    orderBy(userCards, card => card.count.redeemable > 0, 'desc'),
  );

  // Image is never passed: see CardPanel component call to this function.
  function handleCardClick(card: TypeCards) {
    if (!isUserAuthenticated) {
      trackEventManager.trackEvent('loginBtnClick', {
        componentKey: 'cardInfoHiddenForAnonymous',
      });

      openOverloadedContent({
        component: AuthDialog,
        componentProps: {
          componentKey: 'cardInfo',
          location: 'public card information',
        },
      });
      return;
    }

    const itemList = getSortedCards(cards);

    if (isCraftingModeOpen) {
      openCraftingModeCardView(card._id);
      return;
    }

    openOverloadedContent({
      component: CardInformationCarousel,
      componentProps: {
        itemId: card._id,
        itemList,
        hasInfinitePagination: false,
        // eslint-disable-next-line
        onClickEffectSelector: handleClickEffectSelector,
        openFromSource: 'Public collection',
      },
      wrapperProps: {
        opacity: true,
        type: 'large',
        fullOnSmall: false,
        overflowH: true,
      },
    });
  }

  // This function is responsible for returning to the last card viewed when
  // alert effects selector is closed.
  function handleSelectEffect(card: TypeCards) {
    return () => {
      handleCardClick(card);
    };
  }

  // TODO Revise this manage of type of the function because is shared between multiple sites
  function handleClickEffectSelector(card: TypeCards) {
    openOverloadedContent({
      component: Powers,
      componentProps: {
        onSelectEffect: handleSelectEffect(card),
        isPowersPage: false,
      },
      wrapperProps: {
        opacity: true,
        type: 'fullscreen',
        overflowH: true,
        onClose: handleSelectEffect(card),
      },
    });
  }

  if (!cards) {
    return null;
  }

  return (
    <Cards
      hasAppliedFilters={cardFilters.rarities.length > 0 || Boolean(cardFilters.searchString)}
      cards={getSortedCards(cards)}
      craftMode={isCraftingModeOpen}
      isUserAuthenticated={isUserAuthenticated}
      onCardClick={handleCardClick}
      t={t}
    />
  );
};

const mapStateToProps = (state, props): MapStateToProps => {
  const defaultId = userSetsSelectors.setId(state);
  const marathonSet = userSetsSelectors.marathonSpecialSet(state);
  const setId = setIdToView(props.modules, marathonSet, defaultId);
  return {
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    cardFilters: userSetCardsSelectors.filters(state, setId),
    cards: userSetCardsSelectors.filteredCards(state, setId),
  };
};

export { ConnectedUserSetCardsBase as ConnectedUserSetCardsTesting };

export const ConnectedUserSetCards = connect<MapStateToProps, MapDispatchToProps, TranslateInterface>(mapStateToProps, {
  openOverloadedContent: overloadedContentActions.openOverloadedContent,
})(ConnectedUserSetCardsBase);
