import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './buttons.scss';

const classNames = ClassNames(theme);

type ButtonRowProps = {
  className?: string;
  children: React.ReactNode;
  equally?: boolean;
  alignRight?: boolean;
};

const ButtonRow = ({ className = '', children, equally, alignRight }: ButtonRowProps): JSX.Element => (
  <div
    className={classNames({
      'buttonrow': true,
      equally,
      'align-right': alignRight,
      [className]: className,
    })}
  >
    {children}
  </div>
);

ButtonRow.defaultProps = { className: '' };
export default ButtonRow;
