import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { CSSTransition } from 'react-transition-group';
import type { CardCount, CraftableInfo, FragmentConfiguration } from 'model/indexTS';
import type { TranslateInterface } from 'utils/translation';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import { CardFooterCount } from './CardFooterCount';
import theme from './user-set-cards.scss';

const classNames = ClassNames(theme);

interface CardFooterProps extends FragmentConfiguration, CraftableInfo, TranslateInterface {
  count: CardCount;
  craftMode: boolean;
}

const CardFooterInternal = ({
  craftable,
  craftMode,
  count,
  craftingCost,
  disenchantingReward,
  fragmented,
  fragments,
  t,
}: CardFooterProps): JSX.Element | null => {
  const { availableFragments, redeemable } = count;

  if (!availableFragments && !redeemable && !craftMode) {
    return null;
  }

  return (
    <React.Fragment>
      {(availableFragments > 0 || redeemable > 0 || craftMode) && (
        <div className={classNames('card__footer')}>
          {craftMode && (
            <div className={classNames('card__crafting-info')}>
              <div className="color-success">
                +{disenchantingReward} <DisenchantResourceSymbol />
              </div>
              {craftable && (
                <div className="color-error">
                  -{craftingCost} <DisenchantResourceSymbol />
                </div>
              )}
            </div>
          )}
          <CardFooterCount count={count} craftMode={craftMode} t={t} fragmented={fragmented} fragments={fragments} />
        </div>
      )}
    </React.Fragment>
  );
};

export const CardFooter = React.memo(CardFooterInternal);
