import { createSelector } from 'reselect';
import type { OwnerCard, OwnerCardsById } from 'model/indexTS';
import type { SetCardListStatus, SetCardListStateBySetId } from './services/list';
import type { SetCardsState } from './types';

const EMPTY = {};

const setCardsState = (state): SetCardsState => state.setCards;

const setCardListState = (state, setId: string): SetCardListStateBySetId => setCardsState(state).list[setId] || EMPTY;

const setCards = createSelector(
  [setCardListState],
  (setCardListInfo: SetCardListStateBySetId): Array<OwnerCard> | undefined => setCardListInfo.cards,
);

const listStatus = createSelector(
  [setCardListState],
  (setCardListInfoBySetId: SetCardListStateBySetId): SetCardListStatus => {
    const { cancelToken, cards, isLoading, error } = setCardListInfoBySetId;

    return {
      cancelToken,
      cards,
      isLoading,
      error,
    };
  },
);

const newCardOrder = createSelector([setCardListState], (setCardListStateBySetId: SetCardListStateBySetId): number => {
  const { cards } = setCardListStateBySetId;
  return cards ? cards.length + 1 : 1;
});

const cardsById = createSelector(
  [setCardListState],
  (setCardListStateBySetId: SetCardListStateBySetId): OwnerCardsById | undefined =>
    setCardListStateBySetId.setCardsById,
);

const card = createSelector(
  [cardsById, (_state, setId): string => setId, (_state, _setId, cardId): string => cardId],
  (cardsByIdInfo: OwnerCardsById | undefined, _setId: string, cardId: string): OwnerCard | null => {
    if (!cardsByIdInfo) {
      return null;
    }

    return cardsByIdInfo[cardId];
  },
);

const invalidateSetCardList = createSelector(
  [setCardListState],
  (setCardListStateBySetId: SetCardListStateBySetId): boolean => setCardListStateBySetId.invalidateData || false,
);

const hasActivatedCards = createSelector(
  [setCardListState],
  (setCardListStateBySetId: SetCardListStateBySetId): boolean => setCardListStateBySetId.hasActivatedCards || false,
);

const setCardsFilters = createSelector([setCardListState], cardsState => {
  return (
    cardsState.filters || {
      rarities: [],
      search: '',
    }
  );
});

const filteredCards = createSelector([setCardListState], (cardsState): OwnerCard[] | undefined => {
  const { cards, filters } = cardsState;
  const search = (filters && filters.search) || '';
  const rarities = (filters && filters.rarities) || [];

  if (!cards || !Array.isArray(cards) || (!search && !rarities)) {
    return cards;
  }

  return cards.filter(cardItem => {
    const nameToFilterFor = cardItem.normalizedName || cardItem.name || '';
    const descriptionToFilterTo = cardItem.normalizedDescription || cardItem.description || '';

    if (search && nameToFilterFor.indexOf(search) === -1 && descriptionToFilterTo.indexOf(search) === -1) {
      return false;
    }

    if (rarities.length > 0 && rarities.indexOf(cardItem.rarity) === -1) {
      return false;
    }

    return true;
  });
});

export const setCardsSelectors = {
  setCardListState,
  hasActivatedCards,
  card,
  cards: setCards,
  cardsById,
  invalidateSetCardList,
  listStatus,
  newCardOrder,
  filteredCards,
  setCardsFilters,
};
