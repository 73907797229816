import type { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { StripePaymentMethod } from 'model/paymentsTS';
import type { TranslateInterface } from 'utils/translation';
import type { DeleteStripePaymentMethod } from 'services/payment';
import { withTranslation } from 'utils/translation';
import { paymentActions } from 'services/payment';
import { ConfirmationDialogContent, useAsyncConfirmation } from 'components/confirmation-dialog';

interface OwnProps {
  resetPaymentMethodSelected: () => void;
  isSelected: boolean;
  onClose: () => void;
  savedCard: StripePaymentMethod;
}

interface ConnectedDeleteCardProps extends TranslateInterface, OwnProps {
  deletePaymentMethod: DeleteStripePaymentMethod;
}

const ConnectedDeleteCard = (props: ConnectedDeleteCardProps): JSX.Element => {
  const { savedCard, deletePaymentMethod, resetPaymentMethodSelected, isSelected, onClose, t } = props;
  const { finalizeRequest, initRequest, isLoading } = useAsyncConfirmation();

  const handleConfirmClick = () => {
    initRequest();
    if (isSelected) {
      resetPaymentMethodSelected();
    }

    deletePaymentMethod(savedCard._id).then(response => {
      finalizeRequest(response, {
        successMessage: t('paymentMethodDeleted'),
        onSuccess: onClose,
      });
    });
  };

  return (
    <ConfirmationDialogContent
      onClose={onClose}
      confirmationMessage={t('deletePaymentMethodConfirm', {
        last4: savedCard.last4,
      })}
      title={t('deletePaymentMethodConfirmTitle')}
      confirmButtonText={t('common:delete')}
      onConfirmClick={handleConfirmClick}
      isLoading={isLoading}
    />
  );
};

export default compose<FunctionComponent<OwnProps>>(
  connect(null, {
    deletePaymentMethod: paymentActions.deleteStripePaymentMethod,
  }),
  withTranslation(['lootPacks', 'common']),
)(ConnectedDeleteCard);
