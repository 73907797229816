import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import type { Set, UserSet } from 'model/indexTS';
import type { ChangeSelectedSet } from 'services/sets';
import { getUrlWithParams, pages, urls } from 'services/routing';
import Button, { BaseButtonProps } from 'ui-library/button';

const classNames = ClassNames({});

export type SetPublicLinkRenderPropArguments = {
  link: string;
  selected: boolean;
  userSet: UserSet | Set;
};

export interface SetPublicLinkProps extends Pick<BaseButtonProps, 'variant'> {
  changeSelectedSet: ChangeSelectedSet;
  className?: string;
  isDefault: boolean;
  noChangeSelectedSet?: boolean;
  noScroll?: boolean;
  renderProp: (props: SetPublicLinkRenderPropArguments) => ReactNode;
  selected: boolean;
  selectedClassName?: string;
  slug: string;
  userSet?: UserSet | Set | null;
}

export const SetPublicLink = ({
  changeSelectedSet,
  className = '',
  isDefault,
  noChangeSelectedSet,
  noScroll,
  renderProp,
  selected,
  selectedClassName = '',
  slug,
  userSet,
  variant,
}: SetPublicLinkProps): JSX.Element | null => {
  if (!userSet) {
    return null;
  }

  const link = isDefault
    ? urls().USER_PAGE
    : getUrlWithParams(pages.USER_PAGE_COLLECTION, {
        slug,
        setId: userSet._id,
      });

  const handleUserSetClick = () => {
    if (selected || noChangeSelectedSet) {
      return;
    }
    changeSelectedSet(userSet._id);
  };

  return (
    <Button
      component={Link}
      className={classNames({
        [className]: Boolean(className),
        [selectedClassName]: selected,
      })}
      onClick={handleUserSetClick}
      title={userSet.name}
      to={{ pathname: link, state: { noScroll } }}
      data-setid={userSet._id}
      variant={variant}
    >
      {renderProp({ link, selected, userSet })}
    </Button>
  );
};
