import { useContext } from 'react';
import { useTranslation } from 'utils/translation';
import { UserSet } from 'model/setsTS';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import Button from 'ui-library/button';
import stringUtils from 'helpers/stringFormat';
import { pages } from 'services/routing';
import { SetList } from 'components/set-list';
import { PublicPageEmptyMessage } from 'components/public-page-empty-message';
import { useReactAlert } from 'components/react-alert-provider';
import trackEventManager from 'utils/event-manager/trackEventManager';

export interface NoSetInformationProps {
  slug: string;
  userSets: UserSet[];
}

export const NoSetInformation = ({ slug, userSets }: NoSetInformationProps): JSX.Element => {
  const { t } = useTranslation('collection');
  const { showSuccess } = useReactAlert();
  const capitalizedSlug = stringUtils.capitalizeFirstLetter(slug);

  const { open } = useContext(SidebarOpenerContext);

  const handleChangeCollection = () => {
    open({
      component: <SetList sets={userSets} hidePublishBug />,
      attributes: {
        title: t('adminSets:selectCollection'),
      },
    });
  };

  const handleSendHug = () => {
    showSuccess(t('collection:noSets.viewer.sendHugMessage', { slug: capitalizedSlug }));
    trackEventManager.trackEvent('public_page_no_sets_clicked', {}, true);
  };

  if (userSets.length === 0) {
    return (
      <PublicPageEmptyMessage
        icon="cards"
        message={t('collection:noSets.viewer.noSetsMessage', { slug: capitalizedSlug })}
        title={t('collection:noSets.viewer.noSetsTitle')}
      >
        <Button to={pages.STREAMERS} label={t('collection:noSets.viewer.sendHug')} primary onClick={handleSendHug} />
      </PublicPageEmptyMessage>
    );
  }

  return (
    <PublicPageEmptyMessage
      icon="cards"
      message={t('collection:noSets.viewer.noSetPublishedMessage', { slug: capitalizedSlug })}
      title={t('collection:noSets.viewer.noSetPublishedTitle')}
    >
      <Button onClick={handleChangeCollection} label={t('collection:noSets.viewer.changeCollection')} primary />
    </PublicPageEmptyMessage>
  );
};
