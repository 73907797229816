import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { forceCheck } from 'react-lazyload';
import type { Card, UserCard } from 'model/indexTS';
import type { TranslateInterface } from 'utils/translation';
import ILLUSTRATIONS from 'constants/illustrations';
import IllustrationWithInfo from 'components/illustration-with-info';
import CardPanel from './CardPanel';
import theme from './user-set-cards.scss';

const classNames = ClassNames(theme);

type CardsProps = TranslateInterface & {
  hasAppliedFilters: boolean;
  cards: Array<Card>;
  craftMode: boolean;
  isUserAuthenticated: boolean;
  onCardClick: (card: UserCard) => void;
};

const Cards = ({ hasAppliedFilters, cards, craftMode, isUserAuthenticated, onCardClick, t }: CardsProps) => {
  React.useEffect(() => {
    forceCheck();
  }, [cards]);

  if (cards.length === 0) {
    return (
      <IllustrationWithInfo
        className={classNames('cards__empty')}
        imageUrl={hasAppliedFilters ? ILLUSTRATIONS.NO_CARDS : ILLUSTRATIONS.SEARCHING}
      >
        <p>{t(hasAppliedFilters ? 'collection:noCardsFound' : 'collection:noCards')}</p>
      </IllustrationWithInfo>
    );
  }

  return (
    <div className={classNames('cards')}>
      {cards.map(card => (
        <CardPanel
          key={card._id}
          card={card}
          craftMode={craftMode}
          onClick={onCardClick}
          isUserAuthenticated={isUserAuthenticated}
          t={t}
        />
      ))}
    </div>
  );
};

export default Cards;
