import { ReactNode, createContext, useState, useMemo } from 'react';

export interface CraftingModeProviderContextShape {
  isCraftingModeOpen: boolean;
  craftingModeViewForCardId: string;
  openCraftingMode: () => void;
  closeCraftingMode: () => void;
  openCraftingModeCardView: (cardId: string) => void;
  closeCraftingModeCardView: () => void;
}

const context: CraftingModeProviderContextShape = {
  isCraftingModeOpen: false,
  craftingModeViewForCardId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openCraftingMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeCraftingMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openCraftingModeCardView: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeCraftingModeCardView: () => {},
};

export const CraftingModeProviderContext = createContext<CraftingModeProviderContextShape>(context);

interface CraftingModeProviderProps {
  children: ReactNode;
}

export const CraftingModeProvider = ({ children }: CraftingModeProviderProps): JSX.Element => {
  const [isCraftingModeOpen, setIsCraftingModeOpen] = useState(false);
  const [craftingModeViewForCardId, setCraftingModeViewForCardId] = useState('');
  const contextValue = useMemo<CraftingModeProviderContextShape>(
    () => ({
      isCraftingModeOpen,
      craftingModeViewForCardId,
      openCraftingMode: () => setIsCraftingModeOpen(true),
      closeCraftingMode: () => setIsCraftingModeOpen(false),
      openCraftingModeCardView: (cardId: string) => {
        setCraftingModeViewForCardId(cardId);
      },
      closeCraftingModeCardView: () => setCraftingModeViewForCardId(''),
    }),
    [isCraftingModeOpen, craftingModeViewForCardId, setCraftingModeViewForCardId],
  );

  return <CraftingModeProviderContext.Provider value={contextValue}>{children}</CraftingModeProviderContext.Provider>;
};
