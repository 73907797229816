import ClassNames from '@streamloots/classnames';
import type { TranslateInterface } from 'utils/translation';
import TooltipHelpIndicatorWrapper from 'components/help-tooltip';
import { ProgressBar } from 'ui-library/progress-bar';
import theme from './user-set-cards.scss';

const classNames = ClassNames(theme);

interface FragmentCountProps extends TranslateInterface {
  availableFragments: number;
  fragments: number;
}

export const FragmentCount = ({ availableFragments, fragments, t }: FragmentCountProps): JSX.Element => {
  return (
    <TooltipHelpIndicatorWrapper
      className={classNames('card__count')}
      tooltip={t('card:fragmentedCardHelp', { number: fragments })}
    >
      <div>
        <strong className={classNames('card__count__fragments')}>{`${availableFragments}/${fragments}`}</strong>
        <ProgressBar value={availableFragments} max={fragments} />
      </div>
    </TooltipHelpIndicatorWrapper>
  );
};
