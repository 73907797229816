import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Button, { BaseButtonProps, ButtonIcon } from 'ui-library/button';
import Tooltip, { TooltipPlacement } from 'ui-library/tooltip';
import theme from './toolbox.scss';

const classNames = ClassNames(theme);

interface ToolboxIconButtonProps extends BaseButtonProps {
  icon?: string;
  noTextOnSmallRes?: boolean;
  tooltip?: string;
  responsive?: boolean;
}

export const ToolboxIconButton = ({
  className = '',
  icon,
  selected,
  tooltip,
  responsive = true,
  ...rest
}: ToolboxIconButtonProps): JSX.Element => {
  const { disabled, cta, primary } = rest;
  const buttonClassNames = classNames({
    [className]: className,
    'toolbox__button': true,
    'toolbox__button--selected': selected,
    'toolbox__button--cta': cta || primary,
    'toolbox__button--responsive': responsive,
  });

  if (tooltip && icon) {
    return (
      <Tooltip tooltip={tooltip} placement={TooltipPlacement.TOP}>
        <span
          className={classNames({
            'toolbox__tooltip-wrapper': true,
            'toolbox__tooltip-wrapper--disabled': disabled,
          })}
        >
          <ButtonIcon className={buttonClassNames} icon={icon} {...rest} />
        </span>
      </Tooltip>
    );
  }

  if (icon) {
    return <ButtonIcon className={buttonClassNames} icon={icon} {...rest} />;
  }

  return <Button className={buttonClassNames} cta={cta} primary={primary} {...rest} />;
};
