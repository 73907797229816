import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { lazyload } from 'react-lazyload';
import type { UserCard } from 'model/indexTS';
import type { TranslateInterface } from 'utils/translation';
import { CardImage, CardSymbols } from 'components/card';
import { NewLabel } from 'ui-library/new-indicator';
import { CardFooter } from './CardFooter';
import theme from './user-set-cards.scss';

const classNames = ClassNames(theme);

type CardPanelProps = TranslateInterface & {
  craftMode: boolean;
  onClick: (card: UserCard) => void;
  card: UserCard;
  isUserAuthenticated: boolean;
};

const CardPanel = (props: CardPanelProps) => {
  const { card, craftMode, isUserAuthenticated, onClick, t } = props;
  const { count, craftable, craftingCost, disenchantingReward, fragmented, fragments, name, rarity } = card;

  const imageClassNames = classNames({
    'card__image': true,
    'card__image--not-achieved': craftMode && isUserAuthenticated && !count.total,
    'card__image--not-craftable': craftMode && !craftable && !count.total,
    'card__image--not_available': craftMode && count.total > 0 && !count.redeemable,
  });

  return (
    <div className={classNames('card')} onClick={() => onClick(card)} role="button">
      <div className={classNames('card__new-wrapper')}>{card.recentlyPublished && <NewLabel big />}</div>
      <div className={classNames('card__image-wrapper')}>
        <CardImage className={imageClassNames} name={name} card={card} rarity={rarity} />
        <CardSymbols card={card} />
      </div>
      {isUserAuthenticated && (
        <CardFooter
          count={count}
          fragmented={fragmented}
          fragments={fragments}
          craftMode={craftMode}
          craftable={craftable}
          craftingCost={craftingCost}
          disenchantingReward={disenchantingReward}
          t={t}
        />
      )}
    </div>
  );
};

export default lazyload({
  once: true,
  resize: true,
  height: '380px',
  offset: 50,
})(CardPanel);
