import { createSelector } from 'reselect';
import type { PaymentState, PaymentStoreState } from './types';
import type { SavedStripePaymentMethodsStatus } from './services/saved-stripe-payment-methods-list';

const paymentState = (state: PaymentStoreState): PaymentState => state.payment;

const paymentStatus = createSelector(
  [paymentState],
  (paymentStateInfo: PaymentState): string | undefined => paymentStateInfo.paymentStatus,
);

const creditCardBlockedAt = createSelector(
  [paymentState],
  (paymentStateInfo: PaymentState): Date | undefined => paymentStateInfo.creditCardBlockedAt,
);

const savedStripePaymentMethodsStatus = createSelector(
  [paymentState],
  (paymentStateInfo: PaymentState): SavedStripePaymentMethodsStatus =>
    paymentStateInfo.savedStripePaymentMethodsStatus || {},
);

export const paymentSelectors = {
  paymentState,
  creditCardBlockedAt,
  paymentStatus,
  savedStripePaymentMethodsStatus,
};
