import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, Trans, TranslateInterface } from 'utils/translation';
import Icon from 'ui-library/icons';
import theme from './card.scss';

const classNames = ClassNames(theme);

interface CardNotObtainablePropsOwnProps {
  className?: string;
  craftable: boolean;
}

interface CardNotObtainableProps extends TranslateInterface, CardNotObtainablePropsOwnProps {}

const CardNotObtainableInternal = ({ className = '', craftable, t }: CardNotObtainableProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'card__ribbon': true,
        'card__ribbon--unobtainable': true,
        [className]: Boolean(className),
      })}
    >
      {t('card:notObtainable')}
      {craftable && (
        <Trans i18nKey="card:craftingOnly">
          , <Icon id="hammer" /> only
        </Trans>
      )}
    </div>
  );
};

export const CardNotObtainable = withTranslation('card')(
  CardNotObtainableInternal,
) as React.FunctionComponent<CardNotObtainablePropsOwnProps>;
