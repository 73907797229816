import { Rarity } from '@streamloots/streamloots-types';
import { useTranslation } from 'utils/translation';
import { AutocompleteTags } from 'ui-library/tags';
import { cardFieldBuilder } from 'components/card';
import { DropdownOption } from 'ui-library/form-elements';
import { useMemo } from 'react';

interface RarityAutocompleteOwnBaseProps {
  id?: string;
  label?: string;
  placeholder: string;
  wrapperComponentClassName?: string;
  additionalOptions?: DropdownOption[];
}

interface RarityAutocompleteStringModeOwnProps extends RarityAutocompleteOwnBaseProps {
  arrayMode?: false;
  onChange: (tag: Rarity) => void;
  value: Rarity | '';
}

interface RarityAutocompleteArrayModeOwnProps extends RarityAutocompleteOwnBaseProps {
  arrayMode: true;
  onChange: (tags: Rarity[]) => void;
  value: Rarity[];
}

type RarityAutocompleteProps = RarityAutocompleteArrayModeOwnProps | RarityAutocompleteStringModeOwnProps;

export const RarityAutocomplete = ({
  additionalOptions = [],
  value,
  placeholder,
  label,
  id,
  onChange,
  arrayMode,
  wrapperComponentClassName,
}: RarityAutocompleteProps) => {
  const { t } = useTranslation('card');
  const options = useMemo(() => [...cardFieldBuilder.getCardRarities(t), ...additionalOptions], [t, additionalOptions]);
  return (
    <AutocompleteTags
      tagsWrapperClassName={cardFieldBuilder.getCardRaritiesWrapperClass()}
      id={id}
      renderOption={({ className, label: labelOption }) => <div className={className}>{labelOption}</div>}
      label={label}
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={onChange}
      arrayMode={arrayMode}
      wrapperComponentClassName={wrapperComponentClassName}
    />
  );
};
