import { useState } from 'react';
import ClassNames from '@streamloots/classnames';
import config from 'config';
import { captureSentryError } from 'utils/sentry';
import cardImageUrlGetter, { GetCardImageUrlParams } from './cardImageUrlGetter';
import theme from './card.scss';

const classNames = ClassNames(theme);

type CardImageProps = {
  className?: string;
  card: GetCardImageUrlParams;
  frameId?: string;
  name: string;
  rarity?: string;
};

const isWsrv = (src: string): boolean => {
  const { CDN_WSRV_URL } = config.cdn;

  return src.includes(CDN_WSRV_URL);
};

export const CardImage = ({ card, frameId, rarity = '', name, className = '' }: CardImageProps): JSX.Element => {
  const [url, setUrl] = useState(cardImageUrlGetter.getCardImageUrl(card, frameId));

  const handleError = error => {
    captureSentryError('Error fetching image', {
      url,
      exceptionError: error?.message,
    });
    if (isWsrv(url)) {
      setUrl(cardImageUrlGetter.getCardImageUrl(card, frameId, undefined, true));
    }
  };
  return (
    <img
      src={url}
      alt={name}
      className={classNames({
        card__image: true,
        [`card__image--${rarity.toLowerCase()}`]: rarity,
        [className]: className,
      })}
      onError={handleError}
    />
  );
};
