import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import type { CardCount, FragmentConfiguration } from 'model/indexTS';
import type { TranslateInterface } from 'utils/translation';
import { FragmentCount } from './FragmentCount';
import theme from './user-set-cards.scss';

const classNames = ClassNames(theme);

interface CardFooterProps extends FragmentConfiguration, TranslateInterface {
  count: CardCount;
  craftMode: boolean;
}

export const CardFooterCount = ({
  craftMode,
  count: { availableFragments, redeemable },
  fragments,
  fragmented,
  t,
}: CardFooterProps): JSX.Element | null => {
  if (fragmented && fragments && !redeemable) {
    return craftMode || availableFragments > 0 ? (
      <FragmentCount availableFragments={availableFragments} fragments={fragments} t={t} />
    ) : null;
  }

  return craftMode || redeemable > 0 ? <div className={classNames('card__count')}>{`x${redeemable}`}</div> : null;
};
