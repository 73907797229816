import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './stripe.scss';

const classNames = ClassNames(theme);

interface StripeInputProps {
  className?: string;
  component: React.ComponentType<any>;
  label: string;
  onReady?: (name: string) => void;
  name: string;
}

const style = {
  base: {
    fontSize: '16px',
    color: 'rgb(224, 224, 224)',
    lineHeight: '34px',
    height: '34px',
  },
};

type StripeInputEvent = {
  error?: {
    message: string;
  };
};

const StripeInput = ({ className = '', name, onReady, label, component: Component }: StripeInputProps): JSX.Element => {
  const [error, setError] = React.useState('');
  const [focus, setFocus] = React.useState(false);

  const handleChange = (event: StripeInputEvent) => {
    setError(event.error ? event.error.message : '');
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const handleReady = () => {
    if (onReady) {
      onReady(name);
    }
  };

  return (
    <div
      className={classNames({
        'stripe__form-item': true,
        [className]: className,
      })}
    >
      <span className={classNames('stripe__label')}> {label} *</span>
      <Component
        className={classNames({
          'stripe__input': true,
          'stripe__input--focus': focus,
          'stripe__input--error': error,
        })}
        style={style}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onReady={handleReady}
      />
      {error && <div className={classNames('stripe__input-error')}>{error}</div>}
    </div>
  );
};

export default StripeInput;
