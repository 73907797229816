import { connect } from 'react-redux';
import { SetBasicInfo, PublicPageCardFilters, UserSet } from 'model/indexTS';
import { authSelectors } from 'services/auth';
import { userSetsSelectors } from 'services/user-sets';
import { userSetCardsActions, userSetCardsSelectors, FilterCards } from 'services/user-set-cards';
import { pageSelectors } from 'services/page';
import { layoutSelectors } from 'services/layout';
import { Collection } from './Collection';

interface MapStateToProps {
  achievedCards: number;
  cardFilters: PublicPageCardFilters;
  cardsCount: number;
  isUserAuthenticated: boolean;
  setId: string;
  isMobile: boolean;
  userSetBasicInfo: SetBasicInfo | null;
  slug: string;
  isOwner: boolean;
  userSets?: Array<UserSet>;
}

interface MapDispatchToProps {
  filterCards: FilterCards;
}

interface ConnectedCollectionProps extends MapStateToProps, MapDispatchToProps {}

const ConnectedCollection = (props: ConnectedCollectionProps): JSX.Element => {
  return <Collection {...props} />;
};

const mapStateToProps = (state): MapStateToProps => {
  const setId = userSetsSelectors.setId(state);
  return {
    achievedCards: userSetCardsSelectors.achievedCardsCount(state, setId),
    cardsCount: userSetCardsSelectors.cardsCount(state, setId),
    cardFilters: userSetCardsSelectors.filters(state, setId),
    isMobile: layoutSelectors.isMobile(state),
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    userSetBasicInfo: userSetsSelectors.selectedUserSet(state),
    setId,
    slug: pageSelectors.slug(state),
    isOwner: pageSelectors.isOwner(state),
    userSets: userSetsSelectors.userSets(state),
  };
};

export default connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  filterCards: userSetCardsActions.filterCards,
})(ConnectedCollection);
