import { useState, useEffect, useMemo } from 'react';
import { Rarity } from '@streamloots/streamloots-types';
import { forceCheck } from 'react-lazyload';
import ClassNames from '@streamloots/classnames';
import type { FilterCards } from 'services/user-set-cards';
import { TranslateInterface } from 'utils/translation';
import { PublicPageCardSelectableFilters, PublicPageCardFilters } from 'model/indexTS';
import { SearchInput } from 'ui-library/search-input';
import { RarityAutocomplete } from 'components/rarity';
import theme from './collection-filters.scss';

const classNames = ClassNames(theme);

interface CardFiltersProps extends TranslateInterface {
  cardFilters: PublicPageCardFilters;
  filterCards: FilterCards;
  setId: string;
}

export const CardFilters = ({ filterCards, setId, cardFilters, t }: CardFiltersProps): JSX.Element => {
  const { options, searchString, rarities } = cardFilters;
  const [internalSearch, setInternalSearch] = useState(searchString || '');

  const predefinedFilters = useMemo(() => {
    return Object.values(PublicPageCardSelectableFilters).map(option => ({
      label: t(`collection:${option}`),
      value: option,
    }));
  }, [t]);

  useEffect(() => {
    forceCheck();
  }, [cardFilters]);

  const handleSearch = (searchValue: string) => {
    filterCards(
      {
        options,
        rarities,
        searchString: searchValue,
      },
      setId,
    );
  };

  const handleRarityDropdown = (rarityFilters: string[]) => {
    filterCards(
      {
        searchString,
        options,
        rarities: rarityFilters as Rarity[],
      },
      setId,
    );
  };

  return (
    <div className={classNames({ 'collection-filters': true, 'collection-filters--wrap': rarities.length > 0 })}>
      <SearchInput
        id="set-cards-filter"
        showClean
        name="slug"
        onChange={text => setInternalSearch(text)}
        placeholder={t('common:searchCard')}
        className={classNames('collection-filters__search')}
        value={internalSearch}
        onSearch={handleSearch}
      />
      <RarityAutocomplete
        id="set-cards-rarity-filter"
        arrayMode
        placeholder={t('collection:filters')}
        value={rarities}
        additionalOptions={predefinedFilters}
        onChange={handleRarityDropdown}
        wrapperComponentClassName={classNames('collection-filters__rarity')}
      />
    </div>
  );
};
