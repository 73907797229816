import * as React from 'react';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { CraftingModeProviderContext } from 'contexts/crafting-mode-provider';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import { Toolbox, ToolboxIconButton } from 'components/toolbox';
import ClassNames from '@streamloots/classnames';
import theme from './craft-mode-toolbox.scss';

const classNames = ClassNames(theme);

const CraftModeToolboxInternal = ({ t }: TranslateInterface): JSX.Element => {
  const { closeCraftingMode } = React.useContext(CraftingModeProviderContext);

  return (
    <Toolbox
      className={classNames('craft-mode-toolbox')}
      contentWrapperClassName={classNames('craft-mode-toolbox__content-wrapper')}
      contentClassName={classNames('craft-mode-toolbox__content')}
      doNotFloat
    >
      <DisenchantResourceSymbol className={classNames('craft-mode-toolbox__resources')} includeBalance />
      <ToolboxIconButton
        onClick={closeCraftingMode}
        label={t('disenchant:closeCraftingMode')}
        icon="exit"
        responsive={false}
      />
    </Toolbox>
  );
};

export const CraftModeToolbox = withTranslation('disenchant')(CraftModeToolboxInternal) as React.FunctionComponent;
