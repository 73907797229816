import * as React from 'react';
import { compose } from '@reduxjs/toolkit';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import ClassNames from '@streamloots/classnames';
import { reduxForm, formValues, InjectedFormProps } from 'redux-form';
import type { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { SimpleForm, Input, rules } from 'ui-library/forms';
import { useReactAlert } from 'components/react-alert-provider';
import StripeInput from './StripeInput';
import { StripeFormAdditionalValues } from './types';
import theme from './stripe.scss';

const classNames = ClassNames(theme);

type OwnProps = TranslateInterface & {
  buttonLabel: string;
  handleSubmit: (params: StripeFormAdditionalValues) => void;
  onAllItemsLoaded?: () => void;
  isHidden?: boolean;
  isLoading: boolean;
  isDisabled?: boolean;
  renderAdditionalMessages?: () => React.ReactNode;
};

type StripeFormProps = OwnProps &
  InjectedFormProps<StripeFormAdditionalValues> & {
    name: string;
  };

const StripeForm = ({
  buttonLabel,
  name,
  handleSubmit,
  touch,
  isDisabled,
  isLoading,
  onAllItemsLoaded,
  renderAdditionalMessages,
  isHidden = false,
  t,
  valid,
}: StripeFormProps): JSX.Element => {
  const { showError } = useReactAlert();

  const formItemsLoaded = React.useRef({
    name: false,
    card: false,
    expiration: false,
    cvc: false,
  });

  const handleItemReady = (inputName: string) => {
    formItemsLoaded.current[inputName] = true;
    const { card, expiration, cvc } = formItemsLoaded.current;

    if (card && expiration && cvc && onAllItemsLoaded) {
      onAllItemsLoaded();
    }
  };

  return (
    <SimpleForm
      className={classNames({
        'stripe__form': true,
        'stripe__form--hidden': isHidden,
      })}
      handleSubmit={handleSubmit}
      avoidSubmitOnEnter
      renderButton={() => (
        <Button
          onClick={() => {
            if (!valid) {
              touch('name');
              showError(t('common:creditCardForm.cardholderRequired'));
              return;
            }

            // TODO other options hasnt work. Fucking redux form
            handleSubmit({ name });
          }}
          label={buttonLabel}
          loading={isLoading}
          disabled={isDisabled}
          block
          color="checkout"
          variant="filled"
        />
      )}
    >
      <div className={classNames('stripe__form-group')}>
        <StripeInput
          component={CardNumberElement}
          label={t('common:creditCardForm.cardNumber')}
          name="card"
          onReady={handleItemReady}
        />
        <div className={classNames('stripe__form-group-small')}>
          <StripeInput component={CardCVCElement} label="CVC" name="cvc" onReady={handleItemReady} />
          <StripeInput
            component={CardExpiryElement}
            label={t('common:creditCardForm.expiration')}
            name="expiration"
            onReady={handleItemReady}
          />
        </div>
      </div>
      <Input
        id="stripe-cardholderName"
        placeholder="John Wick"
        label={t('common:creditCardForm.cardholderName')}
        name="name"
        validate={rules.required}
        required
      />
      {renderAdditionalMessages && renderAdditionalMessages()}
    </SimpleForm>
  );
};

export default compose<React.FunctionComponent<OwnProps>>(
  reduxForm({
    form: 'stripe',
  }),
  formValues('name'),
)(StripeForm);
