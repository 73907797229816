import { useEffect, useRef, useState } from 'react';
import ClassNames from '@streamloots/classnames';
import { debounce } from 'lodash';
import { useTranslation } from 'utils/translation';
import { setIdToView } from 'domain/public-page';
import Button, { ButtonIcon } from 'ui-library/button';
import { UserSet } from 'model/indexTS';
import { useUserSetsSelectors } from 'services/user-sets';
import { usePageSelectors } from 'services/page';
import { useSetsActions } from 'services/sets';
import { SetPublicLink } from 'components/set/SetPublicLink';
import { DropdownMenu, DropdownMenuPosition } from 'components/dropdown-menu';

import theme from './collection.scss';

const classNames = ClassNames(theme);

export interface CollectionSetPickerProps {
  setId: string;
  userSets: UserSet[];
}

interface SetsToShow {
  asTabs: UserSet[];
  asMenu: UserSet[];
}

export const CollectionSetPicker = ({ setId, userSets }: CollectionSetPickerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const [setsToShow, setSetsToShow] = useState<SetsToShow>({ asTabs: userSets, asMenu: [] });
  const { marathonSpecialSet } = useUserSetsSelectors();
  const {
    page: { slug, modules },
  } = usePageSelectors();
  const { changeSelectedSet } = useSetsActions();
  const selectedSetId = setIdToView(modules, marathonSpecialSet, setId);

  useEffect(() => {
    const updateSets = () => {
      let containerWidth = containerRef.current?.clientWidth ?? 0;
      const ITEM_SIZE = 78;
      const VIEW_MORE_SIZE = 120;
      // We take one extra for additional button

      if (!containerWidth) {
        return;
      }

      // Si hay dos, y c

      const totalSize = ITEM_SIZE + ITEM_SIZE * userSets.length;

      if (totalSize <= containerWidth) {
        setSetsToShow({
          asTabs: userSets,
          asMenu: [],
        });
        return;
      }

      // If code reaches here it will require the button
      containerWidth -= VIEW_MORE_SIZE;
      const asTabsSets: UserSet[] = [];
      const asMenuSets: UserSet[] = [];

      userSets.forEach((userSet, index) => {
        const itemRightPosition = ITEM_SIZE + ITEM_SIZE * index;
        const fitAsTab = itemRightPosition <= containerWidth;

        if (setId === userSet._id) {
          if (!fitAsTab && asTabsSets.length) {
            const lastPrevious = asTabsSets.pop() as UserSet;
            asMenuSets.push(lastPrevious);
          }

          asTabsSets.push(userSet);
          return;
        }

        if (fitAsTab) {
          asTabsSets.push(userSet);
          return;
        }

        asMenuSets.push(userSet);
      });

      setSetsToShow({
        asTabs: asTabsSets,
        asMenu: asMenuSets,
      });
    };
    updateSets();

    const updateSetsListener = debounce(updateSets, 300);
    window.addEventListener('resize', updateSetsListener);

    return () => {
      window.removeEventListener('resize', updateSetsListener);
    };
  }, [userSets, setId]);

  return (
    <div className={classNames('set-buttons')} ref={containerRef}>
      {setsToShow.asTabs.map((userSet, index) => {
        return (
          <SetPublicLink
            changeSelectedSet={changeSelectedSet}
            className={classNames('set-buttons__button')}
            selectedClassName={classNames('set-buttons__button--selected')}
            isDefault={index === 0}
            key={userSet._id}
            variant="ghost"
            noScroll
            renderProp={() => (
              <>
                <img src={userSet.imageUrl} alt={userSet.name} title={userSet.name} />
              </>
            )}
            selected={selectedSetId === userSet._id}
            slug={slug}
            userSet={userSet}
          />
        );
      })}
      {setsToShow.asMenu.length > 0 && (
        <DropdownMenu
          closeOnClick
          position={DropdownMenuPosition.BOTTOM_RIGHT}
          colorBg="grey"
          renderContent={() => (
            <>
              {setsToShow.asMenu.map(userSet => (
                <SetPublicLink
                  changeSelectedSet={changeSelectedSet}
                  isDefault={false}
                  className={classNames('set-buttons__button set-buttons__button--menu')}
                  selectedClassName={classNames('set-buttons__button--selected')}
                  key={userSet._id}
                  noScroll
                  renderProp={() => (
                    <>
                      <img src={userSet.imageUrl} alt={userSet.name} title={userSet.name} />
                      <span className={classNames('set-buttons__name truncate')}>{userSet.name}</span>
                    </>
                  )}
                  selected={selectedSetId === userSet._id}
                  slug={slug}
                  userSet={userSet}
                  variant="ghost"
                />
              ))}
            </>
          )}
          renderComponent={({ onClick }) => (
            <ButtonIcon
              icon="chevron-down"
              className={classNames('set-buttons__button-menu')}
              iconRight
              variant="ghost"
              onClick={onClick}
            >
              <span className="nowrap">{t('common:viewMore')}</span>
            </ButtonIcon>
          )}
        />
      )}
    </div>
  );
};
