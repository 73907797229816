import { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence, Variant } from 'framer-motion';
import { useDrag } from 'react-use-gesture';
import ClassNames from '@streamloots/classnames';
import { useLayoutSelectors } from 'services/layout';
import { OwnerCard, PublicPredefinedCard, UserCard } from 'model/indexTS';
import ConnectedCardInformation from './ConnectedCardInformation';
import { CarouselNavButton } from './CarouselNavButton';
import theme from './card-information.scss';

const classNames = ClassNames(theme);

// Animation
const variants: Record<string, Variant> = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 150 : -150,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
    };
  },
};

// Infinite pagination, ie next item of the last one is the first one
// and the other way around, previous of the last is the first one.
// TODO to utils file/folder if we need this somewhere else
function wrap(arr, currentIndex) {
  const max = arr.length;
  const position = currentIndex % max;

  if (currentIndex > max - 1) {
    return position;
  }

  if (currentIndex < 0) {
    return max + position;
  }

  return currentIndex;
}

type TypeCards = UserCard | PublicPredefinedCard | OwnerCard;

interface CardInformationCarouselProps {
  itemList: TypeCards[];
  itemId: string;
  hasInfinitePagination?: boolean;
  isPredefinedCard?: boolean;
  isPreview?: boolean;
  // Cards.tsx doesn't use this prop this is why I change for undefined
  onClickEffectSelector?: (card: TypeCards) => void;
  openFromSource?: 'Public collection' | 'Open packs' | 'Direct link';
  onClose: () => void;
}

export const CardInformationCarousel = (props: CardInformationCarouselProps): JSX.Element => {
  const { itemList, itemId, hasInfinitePagination = false, ...restOfTheProps } = props;
  const { isMobile } = useLayoutSelectors();

  function initialState(): Array<number> {
    const index = itemList.findIndex(item => item._id === itemId);
    const direction = 0;

    return [index, direction];
  }

  // page is the index of the item in the list of items
  const [[page, direction], setPage] = useState(() => initialState());
  const itemIndex = wrap(itemList, page);
  const paginate = useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
    },
    [page],
  );

  const canNavigateToNext = hasInfinitePagination || (!hasInfinitePagination && page < itemList.length - 1);

  const canNavigateToPrevious = hasInfinitePagination || (!hasInfinitePagination && page > 0);

  const bind = useDrag(
    state => {
      const isLeft = state.direction[0] === -1;

      if (state.distance < 80 || !state.last) {
        return;
      }

      if (isLeft && canNavigateToNext) {
        paginate(1);
      } else if (!isLeft && canNavigateToPrevious) {
        paginate(-1);
      }
    },
    {
      axis: 'x',
    },
  );

  const mobileProps = isMobile
    ? {
        ...bind(),
      }
    : {};

  const handleKeyNavigation = useCallback(
    event => {
      const isField = event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA';

      const ARROW_LEFT = 'ArrowLeft';
      const ARROW_RIGHT = 'ArrowRight';

      if (isField) {
        return;
      }

      if (event.key === ARROW_RIGHT && canNavigateToNext) {
        paginate(1);
      }

      if (event.key === ARROW_LEFT && canNavigateToPrevious) {
        paginate(-1);
      }
    },
    [canNavigateToNext, canNavigateToPrevious, paginate],
  );

  useEffect(() => {
    document.addEventListener('keyup', handleKeyNavigation);
    return () => {
      document.removeEventListener('keyup', handleKeyNavigation);
    };
  }, [canNavigateToNext, canNavigateToPrevious, handleKeyNavigation, paginate]);

  return (
    <>
      <CarouselNavButton disabled={!canNavigateToPrevious} onPaginate={paginate} />
      <AnimatePresence initial={false} custom={direction}>
        <div className={classNames('cardinfo__carousel__wrapper')}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', damping: 20 },
              opacity: { duration: 0.6 },
            }}
            {...mobileProps}
          >
            {itemList[itemIndex] && <ConnectedCardInformation cardId={itemList[itemIndex]._id} {...restOfTheProps} />}
          </motion.div>
        </div>
      </AnimatePresence>

      <CarouselNavButton disabled={!canNavigateToNext} onPaginate={paginate} isNext />
    </>
  );
};
