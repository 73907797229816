import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { StripePaymentMethod } from 'model/paymentsTS';
import type { TranslateInterface } from 'utils/translation';
import { Radio } from 'ui-library/form-elements';
import Dialog from 'ui-library/dialog';
import { ButtonIcon } from 'ui-library/button';
import ConnectedDeletePaymentMethod from './ConnectedDeleteCard';
import theme from './payment.scss';

const classNames = ClassNames(theme);

interface SavedCardsProps extends TranslateInterface {
  savedCards?: Array<StripePaymentMethod>;
  onSelectedCard: (id: string) => void;
  selectedCard: string;
  isOldCheckout?: boolean;
  cardRowClassname?: string;
}

function cardHasExpires(cardMonth, cardYear) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const expirationJsMonth = cardMonth - 1;
  const expirationJsYear = cardYear + 2000;
  return currentYear > expirationJsYear || (currentYear === expirationJsYear && now.getMonth() > expirationJsMonth);
}

const SavedCards = ({
  onSelectedCard,
  savedCards,
  selectedCard,
  isOldCheckout,
  cardRowClassname = '',
  t,
}: SavedCardsProps): JSX.Element | null => {
  const [savedCardToDelete, setSavedCardToDelete] = React.useState<StripePaymentMethod | null>(null);
  if (!savedCards) {
    return null;
  }

  const closeDeleteCardDialog = () => setSavedCardToDelete(null);
  const handleUnselectCard = () => onSelectedCard('');

  return (
    <div
      className={classNames({
        'saved-cards': true,
        'saved-cards--old': isOldCheckout,
      })}
    >
      <div className={classNames('saved-cards__header')}>
        <div className={classNames('saved-cards__header__cards')}>{t('common:creditCardForm.creditAndDebitCards')}</div>
        <div className={classNames('saved-cards__header__name')}>{t('common:creditCardForm.cardholderName')}</div>
        <div className={classNames('saved-cards__header__expiration')}>{t('common:creditCardForm.expiration')}</div>
      </div>
      {savedCards.map(savedMethod => (
        <div
          className={classNames({
            'saved-cards__row': true,
            [cardRowClassname]: Boolean(cardRowClassname),
          })}
          key={savedMethod._id}
        >
          <div className={classNames('saved-cards__radio')}>
            <Radio
              id={savedMethod._id}
              name="savedMethod"
              value={savedMethod._id}
              checked={selectedCard === savedMethod._id}
              onChange={event => onSelectedCard(event.target.value)}
            />
          </div>
          <div className={classNames('saved-cards__card')}>{`${savedMethod.brand} ****${savedMethod.last4}`}</div>
          <div className={classNames('saved-cards__name')} data-title={t('common:creditCardForm.cardholder')}>
            {savedMethod.cardholder || 'unknown'}
          </div>
          <div className={classNames('saved-cards__expiration')} data-title={t('common:creditCardForm.expiration')}>
            {cardHasExpires(savedMethod.expMonth, savedMethod.expYear) ? (
              <span className="color-error">{t('common:creditCardForm.expired')}</span>
            ) : (
              `${savedMethod.expMonth}/${savedMethod.expYear}`
            )}
          </div>
          <div className={classNames('saved-cards__options')}>
            <ButtonIcon
              title={t('common:delete')}
              icon="trash"
              simple
              onClick={() => setSavedCardToDelete(savedMethod)}
              data-testid="button-remove-payment"
            />
          </div>
        </div>
      ))}
      <Dialog
        active={!!savedCardToDelete}
        onEscKeyDown={closeDeleteCardDialog}
        onClose={closeDeleteCardDialog}
        onOverlayClick={closeDeleteCardDialog}
        renderDialogBody={() => {
          if (!savedCardToDelete) {
            return null;
          }

          return (
            <ConnectedDeletePaymentMethod
              onClose={closeDeleteCardDialog}
              savedCard={savedCardToDelete}
              resetPaymentMethodSelected={handleUnselectCard}
              isSelected={savedCardToDelete._id === selectedCard}
            />
          );
        }}
      />
    </div>
  );
};

export default SavedCards;
