import type { ReactNode } from 'react';
import { FixMe } from 'types/indexTS';
import Button from '../button';

type OnFormSubmitProp = (params: FixMe) => FixMe;

type SimpleFormProps = {
  handleSubmit: OnFormSubmitProp;
  buttonLabel?: string;
  children: ReactNode;
  className?: string;
  renderButton: ({ onSubmit: OnFormSubmitProp }) => ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  avoidSubmitOnEnter?: boolean;
  id?: string;
};

export const SimpleForm = ({
  buttonLabel,
  children,
  renderButton,
  isLoading,
  id,
  className = '',
  disabled = false,
  avoidSubmitOnEnter,
  handleSubmit,
}: SimpleFormProps) => {
  const handleSubmitInternal = (event: React.FormEvent) => {
    event.preventDefault();
    if (avoidSubmitOnEnter) {
      return false;
    }
    return handleSubmit(event);
  };

  return (
    <form className={className} onSubmit={handleSubmitInternal} method="POST" data-testid={id}>
      {children}
      {renderButton ? (
        renderButton({ onSubmit: handleSubmitInternal })
      ) : (
        <Button primary label={buttonLabel} type="submit" loading={isLoading} disabled={disabled} />
      )}
    </form>
  );
};
