import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './empty-message.scss';

const classNames = ClassNames(theme);

export interface PublicPageEmptyMessageProps {
  children?: ReactNode;
  icon: string;
  message: string;
  title: string;
  className?: string;
}

export const PublicPageEmptyMessage = ({
  children,
  icon,
  message,
  title,
  className = '',
}: PublicPageEmptyMessageProps): JSX.Element => {
  return (
    <div className={classNames({ empty: true, [className]: Boolean(className) })}>
      <div className={classNames('empty__content')}>
        <div className={classNames('empty__icon-wrapper')}>
          <Icon id={icon} className={classNames('empty__icon')} />
        </div>
        <div>
          <h2 className={classNames('empty__title')}>{title}</h2>
          <p className="margin-bottom">{message}</p>
          {children}
        </div>
      </div>
    </div>
  );
};
