import * as React from 'react';
import _ from 'lodash';
import memoize from 'memoize-one';
import { Label, DropdownOption } from 'ui-library/form-elements';
import theme from './tags.scss';

type WithTagsWrapperProps = {
  onChange: (tags: string, options?: DropdownOption[]) => void;
  value: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
  id?: string;
  error?: string;
  arrayMode?: boolean;
  dropdownMode?: boolean;
  wrapperComponentClassName?: string;
  options: [{ label: string; value: string }];
};

export type WithTagsWrappedProps = {
  onChange: (value: string) => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (tag: string) => void;
  value: string;
  tags: DropdownOption[] | string[];
};

type WithTagsWrapperState = {
  internalValue: string;
  internalTagsOptions: DropdownOption[];
};

const withTags = (WrappedComponent: any): any => {
  class WithTagsWrapper extends React.Component<WithTagsWrapperProps, WithTagsWrapperState> {
    getTags = memoize((value: string | string[] | DropdownOption[], arrayMode?: boolean, dropdownMode?: boolean):
      | string[]
      | DropdownOption[] => {
      if (!value) {
        return [];
      }

      if (arrayMode) {
        return Array.isArray(value) ? value : [value];
      }

      if (dropdownMode) {
        return value as DropdownOption[];
      }

      if (typeof value === 'string') {
        return value.split(',');
      }

      return [];
    });

    constructor(props) {
      super(props);
      const { value, arrayMode, dropdownMode } = this.props;
      this.state = {
        internalValue: '',
        internalTagsOptions: (this.getTags(value, arrayMode, dropdownMode) as DropdownOption[]) || [],
      };
    }

    removeInternalTags = (index: number, callback: () => void) => {
      this.setState(
        state => ({
          ...state,
          internalTagsOptions: [
            ...state.internalTagsOptions.slice(0, index),
            ...state.internalTagsOptions.slice(index + 1),
          ],
        }),
        () => callback(),
      );
    };

    handleRemoveTag = (tagValue: string) => {
      const { value, arrayMode, dropdownMode } = this.props;
      const tags = [...this.getTags(value, arrayMode, dropdownMode)];
      let tagToRemoveIndex: number;

      if (!dropdownMode) {
        tagToRemoveIndex = tags.indexOf(tagValue);
      } else {
        tagToRemoveIndex = (tags as DropdownOption[]).findIndex(tag => tag.value === tagValue);
      }

      tags.splice(tagToRemoveIndex, 1);

      this.removeInternalTags(tagToRemoveIndex, () => {
        const { internalTagsOptions } = this.state;
        if (!dropdownMode) {
          this.handleChangeValue(tags, internalTagsOptions);
        }

        if (dropdownMode) {
          this.handleChangeValue(
            (tags as DropdownOption[]).map(tag => tag.value),
            internalTagsOptions,
          );
        }
      });
    };

    handleAddTag = (tagValue: any) => {
      const { arrayMode, dropdownMode, value, options } = this.props;
      let index: number;

      if (!tagValue) {
        return;
      }

      const tags = [...this.getTags(value, arrayMode, dropdownMode)];

      if (dropdownMode) {
        index = (tags as DropdownOption[]).findIndex(tag => tag.value === tagValue);
      } else {
        index = tags.indexOf(tagValue);
      }

      if (index > -1) {
        return;
      }

      const option = Array.isArray(options) ? _.find(options, tag => tagValue === tag.value) : undefined;

      if (!option) {
        return;
      }

      this.setState(
        state => ({
          ...state,
          internalTagsOptions: [...state.internalTagsOptions, option],
        }),
        () => {
          const { internalTagsOptions } = this.state;
          if (!dropdownMode) {
            tags.push(tagValue);
            this.handleChangeValue(tags, internalTagsOptions);
            return;
          }

          if (dropdownMode) {
            (tags as DropdownOption[]).push(option);
            this.handleChangeValue(
              (tags as DropdownOption[]).map(tag => tag.value),
              tags as DropdownOption[],
            );
          }
        },
      );
    };

    handleChangeValue = (tags: any, internalTags: DropdownOption[]) => {
      const { arrayMode, onChange } = this.props;

      onChange(arrayMode ? tags : tags.join(','), internalTags);
    };

    handleChange = (value: string) => {
      this.setState({ internalValue: value });
    };

    render() {
      const {
        label,
        labelClassName,
        id,
        error,
        required,
        value,
        arrayMode,
        dropdownMode,
        wrapperComponentClassName,
        ...rest
      } = this.props;
      const { internalValue } = this.state;
      const tags = this.getTags(value, arrayMode, dropdownMode);

      return (
        <React.Fragment>
          {label && (
            <Label className={labelClassName} id={id} error={error} required={required}>
              {label}
            </Label>
          )}
          <div className={wrapperComponentClassName}>
            <WrappedComponent
              {...rest}
              onAddTag={this.handleAddTag}
              onRemoveTag={this.handleRemoveTag}
              onChange={this.handleChange}
              tags={tags}
              value={internalValue}
              id={id}
            />
          </div>
          {error && <div className={theme.tag__error}>{error}</div>}
        </React.Fragment>
      );
    }
  }
  return WithTagsWrapper;
};

export default withTags;
