import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import theme from './card.scss';

const classNames = ClassNames(theme);

interface CardLimitedRibbonOwnProps {
  className?: string;
  dropLimited: boolean;
  dropLimitRemaining?: number;
}

interface CardLimitedRibbonProps extends CardLimitedRibbonOwnProps, TranslateInterface {}

const MIN_DROP_TO_SHOW_NUMBER = 5;

const CardLimitedRibbonInternal = ({
  className = '',
  dropLimited,
  dropLimitRemaining = 0,
  t,
}: CardLimitedRibbonProps): JSX.Element | null => {
  if (!dropLimited) {
    return null;
  }

  let i18nKey = 'card:limitedDrop';

  if (dropLimitRemaining === 0) {
    i18nKey = 'card:limitedDropLimitReached';
  } else if (dropLimitRemaining <= MIN_DROP_TO_SHOW_NUMBER) {
    i18nKey = 'card:limitedDropWithInformation';
  }

  return (
    <div
      className={classNames({
        card__ribbon: true,
        [className]: Boolean(className),
      })}
    >
      {t(i18nKey, { count: dropLimitRemaining })}
    </div>
  );
};

export const CardLimitedRibbon = withTranslation('card')(
  CardLimitedRibbonInternal,
) as React.FunctionComponent<CardLimitedRibbonOwnProps>;
