import memoize from 'memoize-one';
import ClassNames from '@streamloots/classnames';
import { Autocomplete, DropdownOption } from 'ui-library/form-elements';
import type { WithTagsWrappedProps } from './types';
import withTags from './withTags';
import Tag from './Tag';
import theme from './tags.scss';

const classNames = ClassNames(theme);

export type AutocompleteTagsProps = WithTagsWrappedProps & {
  tagsWrapperClassName?: string;
  tagClassName?: string;
  options: Array<DropdownOption<string>>;
  onBlur?: () => void;
  onKeyDown?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  id?: string;
  propertyToSearchFor?: string;
};

const dropdownData = memoize((tags: Array<string>, options: Array<DropdownOption<string>>) => {
  if (!options) {
    return {
      selectedOptions: tags,
      filteredOptions: [],
    };
  }
  const filteredOptions: DropdownOption<string>[] = [];
  const selectedOptions = {};
  const unmatchedTags = tags.slice();

  options.forEach(option => {
    const { value } = option;
    if (tags.indexOf(value) > -1) {
      selectedOptions[value] = option;
      const unmatchedTagsIndex = unmatchedTags.indexOf(value);
      unmatchedTags.splice(unmatchedTagsIndex, 1);
    } else {
      filteredOptions.push(option);
    }
  });

  unmatchedTags.forEach(tag => {
    selectedOptions[tag] = { value: tag, label: tag };
  });

  return {
    selectedOptions: tags.map(tag => selectedOptions[tag]).filter(Boolean),
    filteredOptions,
  };
});

const AutocompleteTags = ({
  id,
  tagsWrapperClassName = '',
  tagClassName = '',
  tags,
  options,
  onAddTag,
  onRemoveTag,
  ...rest
}: AutocompleteTagsProps) => {
  const { selectedOptions, filteredOptions } = dropdownData(tags, options);
  return (
    <div
      className={classNames({
        'dropdown--tag': true,
        [tagsWrapperClassName]: Boolean(tagsWrapperClassName),
      })}
    >
      <div
        className={classNames({
          tag__input__group: true,
          [tagClassName]: Boolean(tagClassName),
        })}
      >
        <Autocomplete
          {...rest}
          id={id}
          options={filteredOptions}
          onChange={onAddTag}
          disabled={filteredOptions.length === 0}
        />
      </div>
      {selectedOptions.map(tag => {
        return (
          <Tag key={tag.value} onClick={onRemoveTag} label={tag.label} value={tag.value} className={tag.className} />
        );
      })}
    </div>
  );
};

export default withTags(AutocompleteTags);
