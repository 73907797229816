import { useContext } from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { userSetsToView } from 'domain/public-page';
import { ButtonIcon } from 'ui-library/button';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import { CraftingModeProviderContext } from 'contexts/crafting-mode-provider';
import { PublicPageCardFilters, SetBasicInfo, UserSet } from 'model/indexTS';
import { FilterCards } from 'services/user-set-cards';
import Spinner from 'ui-library/loading-spinner';
import { useUserSetsSelectors } from 'services/user-sets';
import { usePageSelectors } from 'services/page';
import { CraftModeToolbox } from 'scenes/collection-craft-mode';
import { ConnectedUserSetCards } from './components/user-set-cards';
import { NoSetInformation } from './components/no-sets';
import theme from './collection.scss';
import { CardFilters } from './components/filters';
import { CollectionSetPicker } from './CollectionSetPicker';

const classNames = ClassNames(theme);

export interface OwnProps {
  achievedCards: number;
  cardFilters: PublicPageCardFilters;
  cardsCount: number;
  filterCards: FilterCards;
  isUserAuthenticated: boolean;
  setId: string;
  isMobile: boolean;
  userSetBasicInfo: SetBasicInfo | null;
  slug: string;
  isOwner: boolean;
  userSets?: Array<UserSet>;
}

interface CollectionProps extends TranslateInterface, OwnProps {}

const CollectionBase = (props: CollectionProps) => {
  const {
    achievedCards,
    cardsCount,
    filterCards,
    cardFilters,
    isUserAuthenticated,
    setId,
    t,
    userSetBasicInfo,
    userSets,
    slug,
  } = props;
  const { marathonSpecialSet } = useUserSetsSelectors();
  const {
    page: { modules },
  } = usePageSelectors();
  const userSetsToDisplay = userSetsToView(userSets, modules, marathonSpecialSet);
  const userSetBasicInfoToDisplay = modules?.cards.enabled ? userSetBasicInfo : marathonSpecialSet;
  const { isCraftingModeOpen, closeCraftingMode, openCraftingMode } = useContext(CraftingModeProviderContext);

  const handleToggleToolbox = () => {
    if (isCraftingModeOpen) {
      closeCraftingMode();
      return;
    }

    openCraftingMode();
  };

  if (!userSets) {
    return <Spinner />;
  }

  if (!userSetBasicInfoToDisplay) {
    return <NoSetInformation userSets={userSetsToDisplay} slug={slug} />;
  }

  const craftingModeLabel = isCraftingModeOpen ? t('collection:exitCraftingMode') : t('collection:craftModeToggle');

  return (
    <>
      <div className={theme.collection} data-mode={isCraftingModeOpen ? 'crafting' : 'regular'}>
        {userSets?.length > 0 && <CollectionSetPicker setId={setId} userSets={userSetsToDisplay} />}
        {userSetBasicInfoToDisplay && (
          <div className={classNames('collection-body')}>
            {isCraftingModeOpen && <CraftModeToolbox />}
            <div className={classNames({ 'collection-title': true })}>
              <img src={userSetBasicInfoToDisplay.imageUrl} alt="" />
              <div>
                <h1>{userSetBasicInfoToDisplay.name}</h1>
                <div>
                  <strong>{`${achievedCards}/${cardsCount}`}</strong>
                  {` ${t('achieved')}`}
                </div>
              </div>
              {isUserAuthenticated && (
                <div className={classNames('crafting-info')}>
                  <DisenchantResourceSymbol
                    className={classNames('resources')}
                    includeBalance
                    formattedBalance
                    hiddenIfNoResources={!userSetBasicInfoToDisplay.craftableCards}
                  />
                  {userSetBasicInfoToDisplay.craftableCards && (
                    <ButtonIcon
                      asLink
                      icon={isCraftingModeOpen ? 'exit' : 'tools'}
                      label={craftingModeLabel}
                      onClick={handleToggleToolbox}
                    />
                  )}
                </div>
              )}
            </div>

            {isUserAuthenticated && (
              <div className={classNames('margin-bottom')}>
                <CardFilters cardFilters={cardFilters} filterCards={filterCards} setId={setId} t={t} />
              </div>
            )}
            <ConnectedUserSetCards t={t} modules={modules} />
          </div>
        )}
        {isCraftingModeOpen && (
          <>
            <div className={classNames('collection__blur-outside')} onClick={closeCraftingMode} />
          </>
        )}
      </div>
    </>
  );
};

export const Collection = withTranslation(['common', 'collection'])(CollectionBase);
